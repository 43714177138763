<template>
<v-row no-gutters align="center" justify="center">
  <h2>{{category.nom}}</h2>
   <v-container :fluid="$vuetify.breakpoint.mobile" class="pa-0 ma-0">
      <v-row no-gutters justify="center" align="center">
        <v-col
            v-for="partenaire in category.partenaires"
            :key="partenaire.nom"
            :cols="category.partenaires.length===1?12:6" sm="5" md="2"
            class="ma-0 ma-md-1"
            v-if="partenaire.publie"
        >
          <v-card >
            <router-link :to="{ name: 'Single-partenaire', params:{partenaireId:partenaire.id} }" style="text-decoration: none">
            <v-card-title class="card-title not-more-than-3-lines" v-text="partenaire.nom" />
            <v-img :src="getFullURL(partenaire.mediaObjectImage)" height="200px"  contain/>
            </router-link>
            <v-card-actions style="min-height: 50px">
              <v-spacer></v-spacer>
              <v-btn icon v-if="partenaire.siteWeb" target="_blank" :href="partenaire.siteWeb">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
              <v-btn icon v-if="partenaire.facebook" target="_blank" :href="partenaire.facebook">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</v-row>
</template>

<script>
import MediaObjectMixin from "../../mixins/MediaObjectMixin";

export default {
  name: "PartenaireCategory",
  props:['category'],
  mixins:[MediaObjectMixin]
}
</script>

<style scoped lang="scss">
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.not-more-than-3-lines {
  line-height: 1.4em;
  height: 4.5em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
h2 {
  font-family: $font-titre;
  font-weight: bold;
  color: black;
}
.v-card__title.card-title {
  font-family: $font-titre;
  font-weight: bold;
  justify-content: center;
  color: ghostwhite;
  background-color: $bleu;
  min-height: 100px;
  text-align: center;
}
}
</style>