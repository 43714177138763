<template>
  <v-container :fluid="$vuetify.breakpoint.mobile" class="pa-0 ma-0 ma-md-auto">
    <PartenaireCategory v-for="(category,index) in categories" :key="index" :category="category" class="mt-3"/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import PartenaireCategory from "../components/Partenaire/PartenaireCategory";
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

export default {
  name: "Partenaire",
  components: {
    PartenaireCategory,
  },
  created() {
    this.loadCategories();
  },
  computed: {
    ...mapFields('partenaire', ['categories', 'totalCategories']),
  },
  methods: {
    ...mapActions({
      loadCategories: "partenaire/getCategories"
    })
  }
};
</script>
